import {NgModule} from '@angular/core';
import {Route} from '@angular/router';

import {MainComponent} from './main.component';
import {NavigationComponent} from './navigation/navigation.component';

@NgModule({})
export class EmptyModule {}

export const appRoutes: Route[] = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: NavigationComponent,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./navigation/product-passports/product-passports.module').then(
                (m) => m.ProductPassportsModule
              ),
          },
          {
            path: 'alist/:id',
            loadChildren: () =>
              import('./navigation/alist/alist.module').then((m) => m.AlistModule),
          },
          {
            path: 'account',
            loadChildren: () =>
              import('./navigation/account/account.module').then((m) => m.AccountModule),
          },
          {
            path: '01/:product',
            loadChildren: () =>
              import('./navigation/product-passport/product-passport.module').then(
                (m) => m.ProductPassportModule
              ),
          },
          {
            path: ':product',
            loadChildren: () =>
              import('./navigation/product-passport/product-passport.module').then(
                (m) => m.ProductPassportModule
              ),
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
