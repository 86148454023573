import {Breakpoints} from '@alist/breakpoint';
import {FormService} from '@alist/helpers/services/form.service';
import {AListService} from '@aztrix/list/store/alist/alist.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, ElementRef, Input, OnDestroy} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AvatarType} from '@aztrix/components/avatar';
import {ModalSize, OverlayService} from '@aztrix/components/overlay';
import {EnvironmentService} from '@aztrix/environment';
import {avatarUrl, MatomoService, RouterHistoryService} from '@aztrix/helpers';
import {PreviewService} from '@aztrix/list';
import {ProfileService} from '@aztrix/sdk';
import {combineLatest, filter, first, map, shareReplay, startWith, Subscription} from 'rxjs';

import {AlistActiveComponent} from '../alist-active/alist-active.component';
import {AlistSelectComponent} from '../alist-select/alist-select.component';
import {LanguageEditComponent} from '../language-edit/language-edit.component';
import {ShareComponent} from '../share/share.component';
import {SaveStatusComponent} from './save-status/save-status.component';

@Component({
  selector: 'al-sub-navigation',
  templateUrl: './sub-navigation.component.html',
  styleUrls: ['./sub-navigation.component.scss'],
})
export class SubNavigationComponent implements OnDestroy {
  @Input() back = false;

  mobile$ = this._breakpointObserver.observe(Breakpoints.Mobile).pipe(
    map(({matches}) => matches),
    shareReplay(1)
  );

  multipleAlists$ = this._alist.clientAlists$.pipe(
    map((alists) => alists?.length > 1),
    shareReplay(1)
  );

  error$ = this._alist.lastError$;
  allSynced$ = this._alist.allSynced$;

  myProfile$ = this._profile.getMeProfile('MINI').pipe(shareReplay(1));
  avatar$ = this.myProfile$.pipe(map((profile) => avatarUrl(profile)));
  AvatarType = AvatarType;

  currentAlist$ = this._alist.clientAlist$;
  currentAlistLanguage$ = this._alist.clientAlistLanguage$;
  viewURI$ = this._environment.string$('ALIST_VIEW_URI');

  currentRoute$ = this._router.events.pipe(
    filter(
      (event): event is NavigationStart | NavigationEnd =>
        event instanceof NavigationStart || event instanceof NavigationEnd
    ),
    startWith(<NavigationStart>{url: this._router.url})
  );

  account$ = this.currentRoute$.pipe(map((event) => event.url.includes('/account')));

  alistActiveForm$ = this._form.clientAlistActiveForm$;

  currentAlistUrl$ = combineLatest([this.viewURI$, this.currentAlist$]).pipe(
    map(([viewURI, alist]) => `${viewURI}/${alist?.name}*`)
  );

  subscription = new Subscription();

  constructor(
    private _elementRef: ElementRef,
    private _overlayService: OverlayService,
    private _alist: AListService,
    private _breakpointObserver: BreakpointObserver,
    private _routerHistory: RouterHistoryService,
    private _matomo: MatomoService,
    private _profile: ProfileService,
    private _environment: EnvironmentService,
    private _router: Router,
    private _form: FormService,
    private _preview: PreviewService
  ) {
    this.subscription.add(
      this._preview.previewClicked$.subscribe((value) => {
        if (value !== undefined) {
          this.openActiveModal();
        }
      })
    );
  }

  openSaveStatus() {
    this._overlayService.createModal(this._elementRef, SaveStatusComponent, {
      modalSize: ModalSize.DYNAMIC,
    });
  }

  openShare() {
    this._overlayService.createModal(this._elementRef, ShareComponent, {
      modalSize: ModalSize.SMALL,
      title: 'label.share',
    });
  }

  openLanguageSelectModal() {
    this._overlayService.createModal(this._elementRef, LanguageEditComponent, {
      modalSize: ModalSize.DYNAMIC,
      hasTitleBar: false,
    });
  }

  openAlistSelectModal() {
    this._overlayService.createModal(this._elementRef, AlistSelectComponent, {
      modalSize: ModalSize.DYNAMIC,
      hasTitleBar: false,
    });
  }

  openActiveModal() {
    this.currentAlistUrl$
      .pipe(
        first(),
        map((alistUrl) => {
          this._overlayService.createModal(this._elementRef, AlistActiveComponent, {
            modalSize: ModalSize.DYNAMIC,
            hasTitleBar: false,
            init: (modal) => {
              modal.alistUrl = alistUrl;
            },
          });
        })
      )
      .subscribe();
  }

  goBack() {
    this._routerHistory.back('/account');
  }

  matomoTracking() {
    this._matomo.trackEvent('upgrade_tier', 'UPGRADE_MENU');
  }

  ngOnDestroy(): void {
    this._preview.previewClicked$.next(undefined);
    this.subscription.unsubscribe();
  }
}
