import {AListService} from '@aztrix/list/store/alist/alist.service';
import {Component, ElementRef} from '@angular/core';
import {FeedbackSearchComponent} from '@aztrix/components/feedback';
import {OverlayService} from '@aztrix/components/overlay';
import {combineLatest, map} from 'rxjs';

@Component({
  selector: 'al-save-status',
  templateUrl: './save-status.component.html',
  styleUrls: ['./save-status.component.scss'],
})
export class SaveStatusComponent {
  allSynced$ = this._alist.allSynced$;
  errors$ = this._alist.errors$.pipe(map((errors) => Object.values(errors)));
  error$ = this._alist.lastError$;
  saveTimestamp$ = this._alist.saveTimestamp$;

  title$ = combineLatest([this.error$, this.allSynced$]).pipe(
    map(([error, synced]) => {
      if (error) {
        return 'error.default.title';
      }
      return synced ? 'label.saved' : 'label.saving';
    })
  );

  constructor(
    private _elementRef: ElementRef,
    private _alist: AListService,
    private _overlay: OverlayService
  ) {}

  openFeedbackModal() {
    this._overlay.closeModal();
    this._overlay.createModal(this._elementRef, FeedbackSearchComponent);
  }
}
