import {Component} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService, ModeService} from '@aztrix/auth';
import {TranslateService} from '@aztrix/translate';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {first, map} from 'rxjs';

@UntilDestroy()
@Component({
  templateUrl: './main.component.html',
  styleUrls: ['main.component.scss'],
})
export class MainComponent {
  loggedIn$ = this._auth.loggedIn$;
  loggedOut$ = this._auth.loggedOut$;

  languageControl = new FormControl(this._translate.getActiveLang());
  availableLanguages = this._translate.getAvailableLangs();

  mode$ = this._mode.mode$;
  modeParam$ = this._route.data.pipe(map((data) => data['mode']));

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _mode: ModeService,
    private _translate: TranslateService
  ) {
    this._route.queryParams.pipe(first()).subscribe((params) => {
      if (params && params.lang && this.availableLanguages.includes(params.lang)) {
        this.languageControl.setValue(params.lang);
        this._translate.setActiveLang(params.lang);
      }
    });

    this.languageControl.valueChanges.pipe(untilDestroyed(this)).subscribe((language) => {
      this._translate.setActiveLang(language || 'en');
      this._router.navigate([], {
        relativeTo: this._route,
        queryParams: {lang: language},
        queryParamsHandling: 'merge',
      });
    });

    this.loggedOut$.pipe(untilDestroyed(this)).subscribe((loggedOut) => {
      const language = this._translate.getActiveLang();
      if (loggedOut && this.languageControl.value !== language) {
        this._translate.setActiveLang(this.languageControl.value || '');
        document.documentElement.style.setProperty('--height-navbar-mobile', '0px');
      }
    });

    this.loggedIn$.pipe(untilDestroyed(this)).subscribe((loggedIn) => {
      if (loggedIn) {
        document.documentElement.style.setProperty('--height-navbar-mobile', '110px');
      }
    });
  }

  loginComplete({authToken}: {authToken: string | undefined}) {
    if (authToken) {
      this._auth.setAuthToken(authToken);
      this._router.navigate(['']);
    }
  }
}
