<ax-icon
  class="title-icon"
  [name]="
    (error$ | async)
      ? 'content-save-alert-outline'
      : (allSynced$ | async)
      ? 'content-save-check-outline'
      : 'content-save-outline'
  "
></ax-icon>
<h1>{{ title$ | async | translate }}</h1>

<ng-template #timestamp>
  <ng-container *ngIf="saveTimestamp$ | async">
    <p class="timestamp">
      {{ saveTimestamp$ | async | formatDateTimeFromNow }}
    </p>
    <p class="timestamp">({{ saveTimestamp$ | async | formatDateTime }})</p>
  </ng-container>
</ng-template>
<ng-container *ngIf="error$ | async; else timestamp">
  <p>{{ 'error.default.body' | translate }}</p>
  <p *ngFor="let error of errors$ | async">
    <i>{{ error | translate }}</i>
  </p>

  <button axButton (click)="openFeedbackModal()">
    <ax-icon class="icon" name="help-circle-outline"></ax-icon>
    {{ 'label.feedback' | translate }}
  </button>
</ng-container>
