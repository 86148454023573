import {AlistActiveComponent} from '@alist/components/alist-active/alist-active.component';
import {AListService} from '@aztrix/list/store/alist/alist.service';
import {Component, ElementRef, OnDestroy} from '@angular/core';
import {NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import {ModalSize, OverlayService} from '@aztrix/components/overlay';
import {EnvironmentService} from '@aztrix/environment';
import {avatarUrl} from '@aztrix/helpers';
import {PreviewService} from '@aztrix/list';
import {ProfileService} from '@aztrix/sdk';
import {combineLatest, filter, first, map, shareReplay, startWith, Subscription} from 'rxjs';

@Component({
  selector: 'al-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnDestroy {
  currentRoute$ = this._router.events.pipe(
    filter(
      (event): event is NavigationStart | NavigationEnd =>
        event instanceof NavigationStart || event instanceof NavigationEnd
    ),
    startWith(<NavigationStart>{url: this._router.url})
  );

  preview$ = this.currentRoute$.pipe(map((event: RouterEvent) => event.url === '/preview'));

  accountSubroutes$ = this.currentRoute$.pipe(
    map((event: RouterEvent) => event.url.includes('/account/'))
  );
  account$ = this.currentRoute$.pipe(map((event: RouterEvent) => event.url.includes('/account')));

  viewURI$ = this._environment.string$('ALIST_VIEW_URI');
  currentAlistUrl$ = combineLatest([this.viewURI$, this._alist.clientAlist$]).pipe(
    map(([viewURI, alist]) => `${viewURI}/${alist?.name}*`)
  );

  myProfile$ = this._profile.getMeProfile('FULL').pipe(shareReplay(1));
  avatar$ = this.myProfile$.pipe(map((profile) => avatarUrl(profile)));

  subscription = new Subscription();

  constructor(
    private _router: Router,
    private _alist: AListService,
    private _preview: PreviewService,
    private _environment: EnvironmentService,
    private _overlayService: OverlayService,
    private _elementRef: ElementRef,
    private _profile: ProfileService
  ) {
    this.subscription.add(
      this._preview.previewClicked$.subscribe((value) => {
        if (value !== undefined) {
          this.openActiveModal();
        }
      })
    );
  }

  openActiveModal() {
    this.currentAlistUrl$
      .pipe(
        first(),
        map((alistUrl) => {
          this._overlayService.createModal(this._elementRef, AlistActiveComponent, {
            modalSize: ModalSize.DYNAMIC,
            hasTitleBar: false,
            init: (modal) => {
              modal.alistUrl = alistUrl;
            },
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._preview.previewClicked$.next(undefined);
    this.subscription.unsubscribe();
  }
}
