import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AvatarModule} from '@aztrix/components/avatar';
import {ButtonModule} from '@aztrix/components/button';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {NavigationButtonComponent} from '@aztrix/list';
import {TranslatePipe} from '@aztrix/translate';

import {AlistActiveModule} from '../alist-active/alist-active.module';
import {ShareModule} from '../share/share.module';
import {TierWarningModule} from '../tier-warning/tier-warning.module';
import {SaveOptionsComponent} from './save-options/save-options.component';
import {SaveStatusComponent} from './save-status/save-status.component';
import {SubNavigationComponent} from './sub-navigation.component';

@NgModule({
  declarations: [SubNavigationComponent, SaveOptionsComponent, SaveStatusComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NavigationButtonComponent,
    ButtonModule,
    IconsModule,
    ShareModule,
    HelperPipesModule,
    AvatarModule,
    MatSlideToggleModule,
    TierWarningModule,
    AlistActiveModule,
    TranslatePipe,
  ],
  exports: [SubNavigationComponent],
})
export class SubNavigationModule {}
