import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthModule} from '@aztrix/auth';
import {LanguageSelectorComponent} from '@aztrix/list';
import {ThemeModule} from '@aztrix/theme';

import {MainComponent} from './main.component';
import {NavigationModule} from './navigation/navigation.module';

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    RouterModule,
    ThemeModule,
    NavigationModule,
    LanguageSelectorComponent,
    AuthModule,
  ],
  exports: [MainComponent],
})
export class MainModule {}
