// import '@angular/common/locales/global/cs';
// import '@angular/common/locales/global/fr';
// import '@angular/common/locales/global/it';
// import '@angular/common/locales/global/nl';

import {APP_BASE_HREF} from '@angular/common';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {Params, provideRouter} from '@angular/router';
import {AuthState, provideAuth} from '@aztrix/auth';
import {provideOverlay} from '@aztrix/components/overlay';
import {provideEnvironment} from '@aztrix/environment';
import {browserCountryCodeRep, COMMUNICATION_URL_PARAMS, DEFAULT_COUNTRY} from '@aztrix/helpers';
import {provideIcons} from '@aztrix/icons';
import {AlistState} from '@aztrix/list/store/alist/alist.state';
import {PropertiesState} from '@aztrix/list/store/properties/properties.state';
import {TierState} from '@aztrix/list/store/tier/tier.state';
import {provideSdk} from '@aztrix/sdk';
import {provideTranslation} from '@aztrix/translate';
import {environment} from '@env/environment';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsModule} from '@ngxs/store';
import {BehaviorSubject} from 'rxjs';

import {appRoutes} from './routes/app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    provideSdk(location.origin),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    provideRouter(appRoutes),
    provideOverlay(),
    provideEnvironment(),
    provideAuth(),
    provideTranslation({
      availableLangs: ['nl', 'fr', 'en', 'de', 'es', 'it'],
      defaultLang: 'en',
      production: false,
    }),
    provideIcons(),
    importProvidersFrom([
      NgxsModule.forRoot([AuthState, AlistState, PropertiesState, TierState], {
        developmentMode: !environment.production,
      }),
      NgxsStoragePluginModule.forRoot({
        key: ['auth', 'alist.activeAlistId', 'alist.activeAlistLanguageCode'],
      }),
      NgxsReduxDevtoolsPluginModule.forRoot({disabled: environment.production}),
    ]),
    {
      provide: DEFAULT_COUNTRY,
      useValue: new BehaviorSubject(browserCountryCodeRep()),
    },
    {
      provide: COMMUNICATION_URL_PARAMS,
      useValue: new BehaviorSubject<Params>({}),
    },
  ],
};
