<ax-theme
  *ngIf="(mode$ | async) === 'register' || (mode$ | async) === 'verification'"
  primaryColor="#2b5614"
  accentColor="#fdce0c"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ax-theme>
<ax-theme
  *ngIf="(mode$ | async) !== 'register' && (mode$ | async) !== 'verification'"
  primaryColor="#fdce0c"
  accentColor="#2b5614"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ax-theme>

<ng-template #content>
  <div class="wrapper" *ngIf="loggedOut$ | async">
    <lt-language-selector
      class="language-selector"
      [availableLanguages]="availableLanguages"
      [selectedForm]="languageControl"
    ></lt-language-selector>
    <ax-auth
      [mode]="modeParam$ | async"
      (loginComplete)="loginComplete($event)"
      [individualRegisterAllowed]="false"
    ></ax-auth>
  </div>
  <router-outlet *ngIf="loggedIn$ | async"></router-outlet>
</ng-template>
