{
  "name": "aztrix-angular",
  "version": "1.43.0-rc.0",
  "description": "The front-end website of the Aztrix web platform.",
  "homepage": "https://gitlab.com/aztrix/development/aztrix-angular",
  "bugs": {
    "url": "https://aztrix.atlassian.net/jira/software/projects/AZ/boards/23/backlog",
    "email": "hello@aztrix.me"
  },
  "contributors": [
    {
      "name": "Michael Tasiaux",
      "email": "michael@aztrix.me"
    },
    {
      "name": "Marijn Rutten",
      "email": "marijn@aztrix.me"
    },
    {
      "name": "Stef De Brabander",
      "email": "stef_consultant@aztrix.me"
    },
    {
      "name": "Steve Van Opstal",
      "email": "steve_consultant@aztrix.me"
    }
  ],
  "scripts": {
    "ng": "nx",
    "nx": "nx",
    "start:landing": "nx run landing:serve",
    "start:platform": "nx run platform:serve",
    "start:browserstack-safari": "nx run platform:serve:browserstack-safari",
    "start:sign": "nx run sign:serve",
    "start:pdf": "nx run pdf:serve",
    "start:policy": "nx run policy:serve",
    "start:alist": "npm run start:list",
    "start:list": "nx serve alist",
    "start:alist-view": "npm run start:list-view",
    "start:list-view": "nx serve alist-view",
    "start:dpp": "nx run alist:serve:dpp-development",
    "start:esg": "nx serve esg",
    "start:icons": "npm-run-all -p -r start:icons:serve start:icons:server",
    "start:icons:serve": "nx serve icons-test",
    "start:icons:server": "node apps/icons-test/server.js",
    "start:components": "nx serve components-test",
    "start:pages": "nx serve pages-test",
    "start:subscribe": "nx serve subscribe-test",
    "start:elements": "node apps/elements-test/server.js",
    "start:android": "npm run build:capacitor-contacts && npm run build:platform:native && nx run platform:run:android",
    "start:ios": "npm run build:capacitor-contacts && npm run build:platform:native && nx run platform:run:ios",
    "start:capacitor-contacts-test:android": "npm run build:capacitor-contacts && npm run build:capacitor-contacts-test && nx run capacitor-contacts-test:run:android",
    "start:capacitor-contacts-test:ios": "npm run build:capacitor-contacts && npm run build:capacitor-contacts-test && nx run capacitor-contacts-test:run:ios",
    "sync": "nx run platform:sync && nx run capacitor-contacts-test:sync",
    "build": "nx run-many --target=build --all --exclude=aztrix-angular",
    "build:platform": "nx build platform",
    "build:platform:stats": "nx run platform:build:production --statsJson",
    "build:platform:native": "nx run platform:build:native",
    "build:platform:native:prod": "nx run platform:build:native-prod",
    "build:sign": "nx run sign:build:production",
    "build:pdf": "nx run pdf:build:production",
    "build:policy": "nx run policy:build:production",
    "build:libs": "nx run-many --target=build --projects=models,helpers,icons,quill,theme,forms,testing,sdk,components,subscribe,pages",
    "build:models": "nx build models",
    "build:helpers": "nx build helpers",
    "build:icons": "nx build icons",
    "build:components": "nx build components",
    "build:pages": "nx build pages",
    "build:quill": "nx build quill",
    "build:theme": "nx build theme",
    "build:subscribe": "nx build subscribe",
    "build:auth": "nx build auth",
    "build:sdk": "nx build sdk",
    "build:capacitor-contacts": "nx build capacitor-contacts && node scripts/capacitor-contacts-move-build-esm.js && npx --yes rollup -c rollup.config.js --bundleConfigAsCjs && node scripts/capacitor-contacts-move.js",
    "build:capacitor-contacts-test": "nx build capacitor-contacts-test",
    "build:elements-lib": "nx build elements",
    "build:elements": "npm run build --prefix libs/elements",
    "build:elements:dev": "npm run build:dev --prefix libs/elements",
    "build:elements:card": "npm run build:dev --prefix libs/elements -- card",
    "build:elements:subscribe": "npm run build:dev --prefix libs/elements -- subscribe",
    "build:elements:subscribe-button": "npm run build:dev --prefix libs/elements -- subscribe-button",
    "build:elements:subscribe-form": "npm run build:dev --prefix libs/elements -- subscribe-form",
    "build:elements:agreement": "npm run build:dev --prefix libs/elements -- agreement",
    "build:elements:group": "npm run build:dev --prefix libs/elements -- group",
    "build:elements:footer": "npm run build:dev --prefix libs/elements -- footer",
    "build:alist": "nx build alist",
    "build:list": "nx build alist",
    "build:alist-view": "nx build alist-view",
    "build:list-view": "nx build alist-view",
    "build:dpp": "nx run alist:build:dpp-development",
    "build:esg": "nx build esg",
    "build:landing": "nx build landing",
    "bundle-report": "npx webpack-bundle-analyzer -- dist/apps/platform/stats.json -p 8000",
    "sync-translations": "node ./scripts/sync-translations.js",
    "test": "nx run-many --target=test --all --exclude=aztrix-angular --passWithNoTests",
    "test:ci": "nx run-many --target=test --all --exclude=aztrix-angular --ci --codeCoverage --coverageReporters=cobertura --reporters=jest-junit --passWithNoTests",
    "test:coverage": "nx run-many --target=test --all --exclude=aztrix-angular --codeCoverage",
    "test:platform": "nx test platform",
    "test:pdf": "npx --yes nx test pdf",
    "test:models": "nx test models",
    "test:helpers": "nx test helpers",
    "test:components": "nx test components",
    "test:pages": "nx test pages",
    "test:quill": "nx test quill",
    "test:subscribe": "nx test subscribe",
    "test:auth": "nx test auth",
    "test:theme": "nx test theme",
    "test:sdk": "nx test sdk",
    "test:capacitor-contacts": "nx serve capacitor-contacts-test",
    "test:alist": "nx test alist",
    "test:list": "nx test alist",
    "test:esg": "nx test esg",
    "test:landing": "nx test landing",
    "lint": "nx run-many --target=lint --all --exclude=aztrix-angular --fix",
    "lint:platform": "nx lint platform --fix",
    "lint:models": "nx lint models --fix",
    "lint:helpers": "nx lint helpers --fix",
    "lint:icons": "nx lint icons --fix",
    "lint:components": "nx lint components --fix",
    "lint:pages": "nx lint pages --fix",
    "lint:quill": "nx lint quill --fix",
    "lint:subscribe": "nx lint subscribe --fix",
    "lint:auth": "nx lint auth --fix",
    "lint:elements": "nx lint elements --fix",
    "lint:alist": "nx lint alist --fix",
    "lint:list": "nx lint alist --fix",
    "lint:sdk": "nx lint sdk --fix",
    "lint:sdk-esg": "nx lint sdk-esg --fix",
    "lint:landing": "nx lint sdk-esg --fix",
    "format": "npm run lint",
    "lerna": "lerna",
    "publish": "npm run build:libs --skip-nx-cache && npm run build:elements --skip-nx-cache && npm run build:capacitor-contacts --skip-nx-cache && npm run lerna -- publish from-package --pre-dist-tag next",
    "version:prerelease": "npm run lerna -- version prerelease --preid next --conventional-commits --force-publish",
    "version:rc": "npm run lerna -- version preminor --preid rc --conventional-commits --force-publish",
    "version:patch": "npm run lerna -- version patch --conventional-commits --force-publish",
    "version:minor": "npm run lerna -- version minor --conventional-commits --force-publish",
    "index-svgs": "node scripts/index-svgs",
    "merge-coverage": "node scripts/merge-coverage",
    "generate:sdk": "nx run sdk:generate && npm run lint:sdk && nx format:write --projects=sdk && nx build sdk",
    "generate:sdk-esg": "nx run sdk-esg:generate && npm run lint:sdk-esg && nx format:write --projects=sdk-esg && nx build sdk-esg",
    "commitlint": "commitlint"
  },
  "private": true,
  "dependencies": {
    "@angular-architects/native-federation": "^17.1.8",
    "@angular/animations": "^17.3.4",
    "@angular/cdk": "^17.3.3",
    "@angular/common": "^17.3.4",
    "@angular/compiler": "^17.3.4",
    "@angular/core": "^17.3.4",
    "@angular/elements": "^17.3.4",
    "@angular/forms": "^17.3.4",
    "@angular/material": "^17.3.3",
    "@angular/material-date-fns-adapter": "^17.3.10",
    "@angular/platform-browser": "^17.3.4",
    "@angular/platform-browser-dynamic": "^17.3.4",
    "@angular/router": "^17.3.4",
    "@angular/service-worker": "^17.3.4",
    "@capacitor-community/barcode-scanner": "^3.0.1",
    "@capacitor/android": "^4.6.1",
    "@capacitor/app": "^4.1.1",
    "@capacitor/cli": "^4.6.1",
    "@capacitor/core": "^4.6.1",
    "@capacitor/device": "^4.1.0",
    "@capacitor/filesystem": "^4.1.4",
    "@capacitor/geolocation": "^4.1.0",
    "@capacitor/haptics": "^4.1.0",
    "@capacitor/ios": "^4.6.1",
    "@capacitor/push-notifications": "^4.1.2",
    "@mdi/svg": "^7.2.96",
    "@ngneat/reactive-forms": "^5.0.0",
    "@ngneat/transloco": "^6.0.4",
    "@ngneat/until-destroy": "^8.0.4",
    "@ngxs/storage-plugin": "^3.8.1",
    "@ngxs/store": "^3.8.1",
    "@passbase/button": "3.3.1",
    "@stripe/stripe-js": "^1.7.0",
    "@webcomponents/webcomponentsjs": "^2.4.3",
    "@zxing/ngx-scanner": "^17.0.0",
    "angular-container-media-query": "^17.0.0",
    "base64-js": "^1.5.1",
    "chance": "^1.1.4",
    "color": "^3.1.3",
    "core-js": "^3.6.4",
    "date-fns": "^2.15.0",
    "es-module-shims": "^1.5.12",
    "file-saver": "^2.0.2",
    "fuse.js": "^6.4.1",
    "html2pdf.js": "^0.10.1",
    "leaflet": "^1.9.4",
    "libphonenumber-js": "^1.7.48",
    "modern-normalize": "^1.0.0",
    "ng2-charts": "^4.0.0",
    "ng2-pdf-viewer": "^10.0.0",
    "ngx-color-picker": "^16.0.0",
    "ngx-image-cropper": "^7.2.1",
    "ngx-markdown": "^17.1.1",
    "ngx-mat-select-search": "^7.0.5",
    "ngx-quill": "~24.0.4",
    "qrcode": "^1.5.3",
    "quill": "^1.3.7",
    "rxjs": "^7.8.1",
    "tslib": "^2.3.0",
    "ua-parser-js": "2.0.0-beta.2",
    "uuid": "^7.0.2",
    "vcard-parser": "^1.0.0",
    "zone.js": "0.14.3"
  },
  "optionalDependencies": {
    "ios-deploy": "^1.9.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.3",
    "@angular-devkit/core": "^17.3.3",
    "@angular-devkit/schematics": "^17.3.3",
    "@angular-eslint/eslint-plugin": "^17.0.1",
    "@angular-eslint/eslint-plugin-template": "^17.0.1",
    "@angular-eslint/template-parser": "^17.0.1",
    "@angular/cli": "^17.3.3",
    "@angular/compiler-cli": "^17.3.3",
    "@angular/language-service": "^17.3.4",
    "@commitlint/cli": "^11.0.0",
    "@commitlint/config-conventional": "^11.0.0",
    "@ngneat/transloco-validator": "^5.0.1",
    "@ngxs/devtools-plugin": "^3.8.1",
    "@nx/angular": "^17.3.2",
    "@nx/cypress": "^17.3.2",
    "@nx/eslint": "17.3.2",
    "@nx/eslint-plugin": "^17.3.2",
    "@nx/jest": "^17.3.2",
    "@nx/js": "^17.3.2",
    "@nx/web": "17.3.2",
    "@nx/workspace": "^17.3.2",
    "@nxext/capacitor": "^17.0.1",
    "@schematics/angular": "^17.3.3",
    "@swc-node/register": "~1.6.7",
    "@swc/core": "~1.3.85",
    "@swc/helpers": "~0.5.2",
    "@types/base64-js": "^1.3.0",
    "@types/chance": "^1.0.9",
    "@types/color": "^3.0.3",
    "@types/estree": "^1.0.1",
    "@types/file-saver": "^2.0.1",
    "@types/hammerjs": "^2.0.36",
    "@types/jest": "^29.4.0",
    "@types/jsdom": "^21.1.1",
    "@types/leaflet": "^1.9.8",
    "@types/node": "^18.16.9",
    "@types/qrcode": "^1.5.5",
    "@types/quill": "^1.3.7",
    "@types/stripe-v3": "^3.1.20",
    "@types/ua-parser-js": "^0.7.39",
    "@types/uuid": "^9.0.8",
    "@typescript-eslint/eslint-plugin": "6.20.0",
    "@typescript-eslint/parser": "6.20.0",
    "autoprefixer": "^10.4.0",
    "axios": "^0.21.1",
    "concat": "^1.0.3",
    "cypress": "^13.0.0",
    "env2": "^2.2.2",
    "eslint": "8.48.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-cypress": "2.15.1",
    "eslint-plugin-import": "^2.27.5",
    "eslint-plugin-simple-import-sort": "^10.0.0",
    "eslint-plugin-unused-imports": "^3.0.0",
    "express": "^4.17.1",
    "express-http-proxy": "^1.6.0",
    "form-data": "^3.0.0",
    "hammerjs": "^2.0.8",
    "html2pdf.js": "^0.10.1",
    "husky": "^4.3.0",
    "jest": "^29.4.1",
    "jest-environment-jsdom": "^29.4.1",
    "jest-junit": "^13.0.0",
    "jest-marbles": "^3.0.3",
    "jest-preset-angular": "~13.1.4",
    "jsonc-eslint-parser": "^2.1.0",
    "lerna": "^8.0.2",
    "lint-staged": "^10.0.8",
    "ng-packagr": "^17.3.0",
    "npm-run-all": "^4.1.5",
    "nx": "^17.3.1",
    "postcss": "^8.3.9",
    "postcss-import": "14.1.0",
    "postcss-preset-env": "7.5.0",
    "postcss-url": "10.1.3",
    "prettier": "3.1",
    "prettier-plugin-java": "^1.6.2",
    "prettier-plugin-organize-imports": "^3.2.4",
    "pretty-quick": "^4.0.0",
    "swiftlint": "^1.0.1",
    "ts-jest": "^29.1.0",
    "ts-node": "10.9.1",
    "typescript": "~5.3.2",
    "xml2js": "^0.4.23"
  },
  "overrides": {
    "@ngneat/transloco": {
      "flat": "5.0.2"
    }
  }
}
