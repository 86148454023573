import {AListService} from '@aztrix/list/store/alist/alist.service';
import {Component} from '@angular/core';
import {OverlayService} from '@aztrix/components/overlay';

@Component({
  selector: 'al-save-options',
  templateUrl: './save-options.component.html',
  styleUrls: ['../sub-navigation-options.scss', './save-options.component.scss'],
})
export class SaveOptionsComponent {
  constructor(
    private _alist: AListService,
    private _overlay: OverlayService
  ) {}

  reset() {
    this._alist.reset();
    this._overlay.closeModal();
  }

  save() {
    this._alist.save();
    this._overlay.closeModal();
  }
}
