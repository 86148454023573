<div class="alist-select" *ngIf="(account$ | async) === false">
  <ax-button
    class="name-button"
    color="grey"
    fullWidth
    spacing="MEDIUM"
    (click)="openAlistSelectModal()"
  >
    <span class="data">{{
      (currentAlist$ | async)?.domainEnabled
        ? (currentAlist$ | async)?.domain
        : (currentAlistUrl$ | async)
    }}</span>
    <ax-icon class="icon icon-arrow" name="arrow-down"></ax-icon>
  </ax-button>
  <ax-button
    class="language-button"
    color="grey"
    spacing="MEDIUM"
    (click)="openLanguageSelectModal()"
  >
    <span class="data">{{ (currentAlistLanguage$ | async)?.language | uppercase }}</span>
    <ax-icon class="icon icon-arrow" name="arrow-down"></ax-icon>
  </ax-button>

  <ng-container *ngIf="(mobile$ | async) === false" [ngTemplateOutlet]="status"></ng-container>
</div>

<div class="content-right">
  <lt-navigation-button
    *ngIf="back && (mobile$ | async) && (account$ | async)"
    class="back-button"
    color="grey"
    icon="arrow-left"
    [small]="true"
    [ariaLabel]="'label.back' | translate"
    (click)="goBack()"
  ></lt-navigation-button>
  <ng-container
    *ngIf="(mobile$ | async) && (account$ | async) === false"
    [ngTemplateOutlet]="status"
  ></ng-container>
  <lt-navigation-button
    class="share-button"
    icon="share-variant-outline"
    color="grey"
    [small]="true"
    [ariaLabel]="'label.share' | translate"
    (click)="openShare()"
  ></lt-navigation-button>
  <lt-navigation-button
    class="upgrade"
    color="primary"
    icon="lightning-bolt-outline"
    [small]="true"
    [label]="'label.upgrade' | translate"
    url="/account/billing/tiers"
    (click)="matomoTracking()"
  ></lt-navigation-button>
  <lt-navigation-button
    *ngIf="(mobile$ | async) === false"
    url="account"
    [small]="true"
    [ariaLabel]="'me.account.profile.title' | translate"
  >
    <ax-avatar class="avatar" [source]="(avatar$ | async) || undefined" [round]="false"></ax-avatar>
  </lt-navigation-button>
</div>

<ng-template #status>
  <lt-navigation-button
    [icon]="(alistActiveForm$ | async)?.value ? 'eye-outline' : 'eye-off-outline'"
    color="grey"
    [iconColor]="(alistActiveForm$ | async)?.value ? '' : 'red'"
    [small]="true"
    [ariaLabel]="'label.save.changes' | translate"
    (click)="openActiveModal()"
  ></lt-navigation-button>
  <lt-navigation-button
    class="save-button"
    *ngIf="(error$ | async) || (allSynced$ | async) === false"
    [icon]="
      (error$ | async)
        ? 'content-save-alert-outline'
        : (allSynced$ | async)
          ? 'content-save-check-outline'
          : 'content-save-outline'
    "
    color="grey"
    [iconColor]="(error$ | async) ? 'red' : 'green'"
    [loading]="(allSynced$ | async) === false"
    [small]="true"
    [ariaLabel]="'label.save.changes' | translate"
    (click)="openSaveStatus()"
  ></lt-navigation-button>
</ng-template>
