import {Component, HostBinding} from '@angular/core';
import {NavigationEnd, NavigationStart, Router, RouterOutlet} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter, map, startWith} from 'rxjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import packageJson from '../../../../../package.json';
import {MainModule} from './routes/main.module';

@UntilDestroy()
@Component({
  selector: 'al-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, MainModule],
})
export class AppComponent {
  @HostBinding('attr.app-version') version = packageJson.version;

  currentRoute$ = this._router.events.pipe(
    filter(
      (event): event is NavigationStart | NavigationEnd =>
        event instanceof NavigationStart || event instanceof NavigationEnd
    ),
    startWith(<NavigationStart>{url: this._router.url})
  );
  isAlistRoute$ = this.currentRoute$.pipe(map((event) => event.url.startsWith('/alist')));

  constructor(private _router: Router) {
    this.isAlistRoute$.pipe(untilDestroyed(this)).subscribe((isAlistRoute) => {
      if (!isAlistRoute) {
        document.documentElement.style.setProperty('--height-navbar-mobile', '0px');
      } else {
        document.documentElement.style.setProperty('--height-navbar-mobile', '110px');
      }
    });
  }
}
