<ng-template #main>
  <lt-navigation-button
    url="/"
    icon="package-variant-closed"
    [small]="true"
    [label]="'label.products' | translate"
  ></lt-navigation-button>
  <lt-navigation-button
    class="upgrade"
    color="primary"
    icon="lightning-bolt-outline"
    [small]="true"
    [label]="'label.upgrade' | translate"
    url="/account/billing/tiers"
  ></lt-navigation-button>
  <lt-navigation-button
    url="account"
    color="primary"
    [small]="true"
    [ariaLabel]="'me.account.profile.title' | translate"
  >
    <ax-avatar class="avatar" [source]="avatar$ | async" [round]="false"></ax-avatar>
  </lt-navigation-button>
</ng-template>

<div class="header">
  <nav class="navigation">
    <ng-template [ngTemplateOutlet]="main"></ng-template>
  </nav>
</div>

<div class="configuration">
  <router-outlet></router-outlet>
  <ax-logo *ngIf="account$ | async" class="aztrix-logo"></ax-logo>
</div>
