import {SubNavigationModule} from '@alist/components/sub-navigation/sub-navigation.module';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {AvatarModule} from '@aztrix/components/avatar';
import {LogoModule} from '@aztrix/components/logo';
import {IconsModule} from '@aztrix/icons';
import {NavigationButtonComponent} from '@aztrix/list';
import {TranslatePipe} from '@aztrix/translate';

import {NavigationComponent} from './navigation.component';

@NgModule({
  declarations: [NavigationComponent],
  imports: [
    CommonModule,
    RouterOutlet,
    IconsModule,
    SubNavigationModule,
    NavigationButtonComponent,
    LogoModule,
    AvatarModule,
    TranslatePipe,
  ],
  exports: [NavigationComponent],
})
export class NavigationModule {}
